<template>
    <div class="">
        <el-card>
            <el-input v-model="expressWaybillNum" clearable placeholder="请输入快递单号"></el-input>
            <el-button type="primary" @click="search">查询</el-button>
        </el-card>
        <el-card>
            <el-timeline>
                <el-timeline-item v-for="(item, index) in list" :key="index" :timestamp="item.ftime">
                    {{ item.context }}
                </el-timeline-item>
            </el-timeline>
        </el-card>
    </div>
</template>

<script>
export default {
    name: '',
    components: {

    },
    data() {
        return {
            list: [],
            expressWaybillNum: '',
        }
    },
    computed: {

    },
    created() {

    },
    methods: {
        search() {
            fetch(`https://testapi.ziyous.cn/delivery/user/api/getWayBillInfo?expressWaybillNum=${this.expressWaybillNum}`)
                .then(response => response.json())
                .then(res => {
                    this.list = JSON.parse(res.data.expressDetails)
                })
        }
    },
}
</script>

<style  scoped>
.el-input {
    width: 200px;
    margin-right: 50px;
}

.el-card {
    margin-bottom: 20px;

}
</style>